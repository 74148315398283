export enum IconsType {
  activity = 'activity',
  activityLog = 'activityLog',
  addDocument = 'addDocument',
  addStickyNotes = 'addStickyNotes',
  addUsers = 'addUsers',
  alertTriangle = 'alertTriangle',
  analysis = 'analysis',
  approveTick = 'approveTick',
  arrow = 'arrow',
  arrowRight = 'arrowRight',
  assets = 'assets',
  autoAwesome = 'autoAwesome',
  barGraph = 'barGraph',
  basicInfo = 'basicInfo',
  benchmarking = 'benchmarking',
  benchmark = 'benchmark',
  benchmarkManagement = 'benchmarkManagement',
  building = 'building',
  calendar = 'calendar',
  camera = 'camera',
  checkWithCircle = 'checkWithCircle',
  closeIntegralLogo = 'closeIntegralLogo',
  cognitiveBrain = 'cognitiveBrain',
  collapsibleSidebar = 'collapsibleSidebar',
  competitorAnalysis = 'competitorAnalysis',
  cross = 'cross',
  dangerous = 'dangerous',
  dashboard = 'dashboard',
  delete = 'delete',
  documentManagement = 'documentManagement',
  dotSeachTwo = 'dotSeachTwo',
  dollarInCircle = 'dollarInCircle',
  doubleLeft = 'doubleLeft',
  doubleRight = 'doubleRight',
  doubleTick = 'doubleTick',
  download = 'download',
  economicAnalysis = 'economicAnalysis',
  edit = 'edit',
  excel = 'excel',
  eye = 'eye',
  eyeInvisible = 'eyeInvisible',
  eyeVisible = 'eyeVisible',
  fictionalLogo = 'fictionalLogo',
  file = 'file',
  file2 = 'file2',
  fileCopy = 'fileCopy',
  files = 'files',
  filter = 'filter',
  footnote = 'footnote',
  functionalAnalysis = 'functionalAnalysis',
  functions = 'functions',
  gear = 'gear',
  graph = 'graph',
  greenTick = 'greenTick',
  greenTick2 = 'greenTick2',
  halfArrow = 'halfArrow',
  handShake = 'handShake',
  helpOutline = 'helpOutline',
  helpSolid = 'helpSolid',
  intangible = 'intangible',
  integralOpenLogo = 'integralOpenLogo',
  interExchange = 'interExchange',
  labelIcon = 'labelIcon',
  landingPage = 'landingPage',
  leftParagraph = 'leftParagraph',
  legalEntityManagement = 'legalEntityManagement',
  lightBulb = 'lightBulb',
  listBullet = 'listBullet',
  loading = 'loading',
  loadingThree = 'loadingThree',
  locationPointer = 'locationPointer',
  manufacturing = 'manufacturing',
  minus = 'minus',
  modernRightArrow = 'modernRightArrow',
  moneyStack = 'moneyStack',
  networkTree = 'networkTree',
  newFeedBack = 'newFeedBack',
  newFilter = 'newFilter',
  onboardingIllustration = 'onboardingIllustration',
  organization = 'organization',
  options = 'options',
  paragraph = 'paragraph',
  percentage = 'percentage',
  pieChart = 'pieChart',
  plus = 'plus',
  productCategory = 'productCategory',
  region = 'region',
  reset = 'reset',
  risks = 'risks',
  rocket = 'rocket',
  save = 'save',
  search = 'search',
  services = 'services',
  settings = 'settings',
  shoppingCart = 'shoppingCart',
  sideBarEclipse = 'sideBarEclipse',
  sixDotDrag = 'sixDotDrag',
  Smile = 'Smile',
  sort = 'sort',
  sortDescending = 'sortDescending',
  sparkle = 'sparkle',
  stickyNotes = 'stickyNotes',
  stopCircle = 'stopCircle',
  successTick = 'successTick',
  templateManagement = 'templateManagement',
  templateSubType = 'templateSubType',
  threeUsers = 'threeUsers',
  thumbsUp = 'thumbsUp',
  tick = 'tick',
  transaction = 'transaction',
  transactionManagement = 'transactionManagement',
  twoPagerStore = 'twoPagerStore',
  upload = 'upload',
  userBusinessCeo = 'userBusinessCeo',
  users = 'users',
  verified = 'verified',
  blueArrow = 'blueArrow',
}
