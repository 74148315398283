import axios from 'api'

export const getFunctionalProfile = () =>
  axios
    .get<FunctionalProfileResponse[]>('/api/v1/org/{organization}/transaction/functional_profile/')
    .then(res => res.data)

export const getFunctionalProfileById = (id: number) =>
  axios
    .get<FunctionalProfileResponse>(`/api/v1/org/{organization}/transaction/functional_profile/${id}/`)
    .then(res => res.data)

export const createTransactionNature = ({
  transaction_nature,
  transaction_type,
  source_party_identification,
  target_party_identification,
}: {
  transaction_nature: string
  transaction_type: number
  source_party_identification: string
  target_party_identification: string
}) =>
  axios
    .post<FunctionalProfileResponse>('/api/v1/org/{organization}/transaction/functional_profile/', {
      transaction_nature,
      transaction_type,
      source_party_identification,
      target_party_identification,
    })
    .then(res => res.data)

export const editTransactionNature = ({
  transaction_nature,
  transaction_type,
  source_party_identification,
  target_party_identification,
  id,
}: {
  transaction_nature: string
  transaction_type?: number
  source_party_identification: string
  target_party_identification: string
  id: number
}) =>
  axios
    .patch<FunctionalProfileResponse>(`/api/v1/org/{organization}/transaction/functional_profile/${id}/`, {
      transaction_nature,
      transaction_type,
      source_party_identification,
      target_party_identification,
    })
    .then(res => res.data)

export interface FunctionalProfileResponse {
  id: number
  transaction_nature: string
  source_party_identification: string
  target_party_identification: string
  added_by_integral: boolean
  transaction_type: number
  organization: null | string
}
