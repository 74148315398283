import { IconProps } from 'assets'

const TwoPagerStore = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V11H20V6H13V11H11V6H4V18H11V20H4ZM15.5 22C15.0833 22 14.7292 21.8542 14.4375 21.5625C14.1458 21.2708 14 20.9167 14 20.5V17.75H16V20H20V17.75H22V20.5C22 20.9167 21.8542 21.2708 21.5625 21.5625C21.2708 21.8542 20.9167 22 20.5 22H15.5ZM14.575 18C14.1083 18 13.7333 17.8167 13.45 17.45C13.1667 17.0833 13.0667 16.6667 13.15 16.2L13.55 14.2C13.6333 13.85 13.8083 13.5625 14.075 13.3375C14.3417 13.1125 14.65 13 15 13H21C21.35 13 21.6583 13.1125 21.925 13.3375C22.1917 13.5625 22.3667 13.85 22.45 14.2L22.85 16.2C22.9333 16.6667 22.8333 17.0833 22.55 17.45C22.2667 17.8167 21.8917 18 21.425 18H14.575ZM15.225 16H20.775L20.575 15H15.425L15.225 16ZM5 16H10V14H5V16ZM5 13H10V11H5V13ZM5 10H10V8H5V10ZM14 10V8H19V10H14Z"
        fill="black"
      />
    </svg>
  )
}

export default TwoPagerStore
