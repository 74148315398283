import {
  AutoAwesomeOutlined,
  AutoGraphOutlined,
  DashboardOutlined,
  FolderCopyOutlined,
  HandshakeOutlined,
  HelpCenterOutlined,
  LanguageOutlined,
  MapsHomeWorkOutlined,
  // Summarize,
} from '@mui/icons-material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { IconsType } from 'assets/types'
import { ROUTES } from 'constants/routes'
import { ROUTES_TITLES } from 'organisms/layout/constants/layout.constants'

export const getMenus = (props?: { hideICA?: boolean; hideChat?: boolean }): IMenu[] => [
  {
    type: 'Entities',
    fields: [
      { title: ROUTES_TITLES[ROUTES.DASHBOARD], src: DashboardOutlined, path: ROUTES.DASHBOARD },
      { title: ROUTES_TITLES[ROUTES.ORGANIZATION], src: LanguageOutlined, path: ROUTES.ORGANIZATION },
      {
        title: ROUTES_TITLES[ROUTES.LEGAL_ENTITY_MANAGEMENT],
        src: MapsHomeWorkOutlined,
        path: ROUTES.LEGAL_ENTITY_MANAGEMENT,
      },
      {
        title: ROUTES_TITLES[ROUTES.TRANSACTION_MANAGEMENT],
        path: ROUTES.TRANSACTION_MANAGEMENT,
        moduleIcon: IconsType.transactionManagement,
      },
      {
        title: ROUTES_TITLES[ROUTES.IC_AGREEMENTS_MANAGEMENT],
        src: HandshakeOutlined,
        path: ROUTES.IC_AGREEMENTS_MANAGEMENT,
        hide: !!props?.hideICA,
      },
      {
        title: ROUTES_TITLES[ROUTES.DOCUMENT_ARCHIVAL],
        src: FolderCopyOutlined,
        path: ROUTES.DOCUMENT_ARCHIVAL,
      },
      { title: ROUTES_TITLES[ROUTES.CHAT_BOT], src: AutoAwesomeOutlined, path: ROUTES.CHAT_BOT, hide: props?.hideChat },
      {
        title: ROUTES_TITLES[ROUTES.BENCHMARK_MANAGEMENT],
        moduleIcon: IconsType.benchmark,
        path: ROUTES.BENCHMARK_MANAGEMENT,
      },
      // { title: 'Templates', src: Summarize, path: ROUTES.TEMPLATE_MANAGEMENT },
      { title: ROUTES_TITLES[ROUTES.ACTIVITY_LOG], src: AutoGraphOutlined, path: ROUTES.ACTIVITY_LOG },
      { title: ROUTES_TITLES[ROUTES.HELP_CENTER], src: HelpCenterOutlined, path: ROUTES.HELP_CENTER },
    ],
  },
  // {
  //   type: 'Management',
  //   fields: [
  //     { title: 'Setting & Support', src: Settings, path: ROUTES.SETTINGS },
  //     { title: 'User Management', src: People, path: ROUTES.USER_ACCESS_MANAGEMENT },
  //   ],
  // },
]

export interface IMenu {
  type: string
  fields: ISidearField[]
}

export interface ISidearField {
  title: string
  src?:
    | (OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
        muiName: string
      })
    | string
  path: string
  moduleIcon?: IconsType
  subfields?: ISidearField[]
  hide?: boolean
}
