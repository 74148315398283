import { IconProps } from 'assets'
import React from 'react'

const Benchmark = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 16.95V19H19V10.25L13.05 17L9 12.95L5 16.95ZM5 14.1L9 10.1L12.95 14.05L19 7.25V5H5V14.1ZM5 10.25V7.25V14.05V10.1V16.95V12.95V17V10.25ZM5 14.1V5V14.05V10.1V14.1ZM5 16.95V12.95V17V10.25V19V16.95Z"
        fill="black"
      />
    </svg>
  )
}

export default Benchmark
